.sectionText {
    margin-bottom: 36px;
  
    @media (--viewportMedium) {
      margin-bottom: 40px;
    }
  }
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 14px;
    margin-bottom: 24px;
    & svg {
      fill: transparent;
      cursor: pointer;
    }
    & .titleText {
      margin: 0;
      padding: 0;
      color: var(--Web-Primary, #3d70a1);
      font-family: Nunito;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
  }
  .educationContent {
    & ul {
      list-style: initial;
      & li {
        color: var(--Black-Shade-Para_Text, #949494);
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        padding: 0;
        margin-left: 28px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
  .workExperience {
    & ul {
      list-style: initial;
      & li {
        color: var(--Black-Shade-Para_Text, #949494);
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        padding: 0;
        margin-left: 28px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
  .serveFor {
    & .serveForText {
      color: var(--Black-Shade-Para_Text, #949494);
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 45px;
      background: #fff;
      margin: 0;
      padding: 6px 10px;
      display: inline-block;
      text-transform: capitalize;
      margin: 0 7px 7px 0;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  .descriptionText {
    color: var(--Black-Shade-Para_Text, #949494);
    text-align: justify;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
    word-break: break-word;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .serveDescription {
    color: var(--Black-Shade-Para_Text, #949494);
    text-align: justify;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 24px;
    background: #fff;
    text-transform: capitalize;
    margin: 0;
    padding: 6px 14px;
    width: fit-content;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  