@import '../../../styles/customMediaQueries.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}


.avatar {
  flex-shrink: 0;
  margin: 0 43px 0px 0;
    width: 244px;
    height: 244px;
    @media (max-width: 1200px) {
      margin: 0 24px 0px 0;
      width: 200px;
      height: 200px;
    }
    @media (max-width: 1200px) {
      width: 130px;
      height: 130px;
    }
}

.info {
  flex: 1;
  & .doctorName {
    color: var(--Apps-Apps_Hilight, #e28415);
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin: 23px 0 17px;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
}
.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: inline;

    &::before {
      content: ' ';
    }
  }
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.links {
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  composes: p from global;
  display: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  composes: p from global;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  padding: 9.5px 2.5rem;
  border: none;
  background: var(--Web-Primary, #3D70A1);
  cursor: pointer;
  border-radius: 5px;
  color: var(--Black-Shade-White, #fff);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 169px;
  margin-top: 20px;
  &:disabled{
    cursor: not-allowed;
    color: #FFF;
    background-color: #949494;
  }
}

.education {
  color: #505050;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 13px;
  word-break: break-word;
}

.specialities {
  display: flex;
  flex-wrap: wrap;
  /* width: 186px; */
  padding: 10px 0;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  text-transform: capitalize;
  margin-bottom: 1rem;

  & .speciality {
    border-radius: 45px;
    background: #dff8ff;
    padding: 5px 28px;
    color: var(--Web-Primary, #3d70a1);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.ratingContent {
  display: flex;
  gap: 4px;
  & .ratingText {
    margin: 0;
    padding: 0;
    margin-top: 6px;
    margin-left: 8px;
    color: var(--Black-Shade-Para_Text, #949494);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: inherit;
  }
}
.starIconGold {
  color: #e28415;
  font-size: 30px;
}
.starIconGrey {
  color: rgb(199, 199, 199);
  font-size: 30px;
}

/* Add these classes to your stylesheet or modify as needed */

/* Half-filled star */
.starIconHalfGold {
  color: grey;
  font-size: 30px;
  position: relative;
  display: inline-block;
  width: 1em;
}



/* Empty star */
.starIconGrey {
  color: grey;
}

.notAllowed{
  color: gray;

}
.error{
  color: red;
}